/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last  1024 versions
*/

/* --- Textlayout File Css Start --- */
.textlayout-main-bx{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    gap: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 50px 0;
}

.textlayout-main-bx .tlm-heading{
    font-size: 54px;
    color: var(--color1);
    text-align: center;
    font-weight: 800;
    line-height: normal;
    margin-bottom: -5px;
}

.textlayout-main-bx .tlm-heading.heading-24{
    font-size: 24px;
}

.textlayout-main-bx .tlm-sub-text{
    font-size: 16px;
    color: var(--color2);
    text-align: center;
    max-width: 931px;
}

.textlayout-main-bx .tlm-btns{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    gap: 10px;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
}
/* --- Textlayout File Css End --- */


@media screen and (max-width:768px) {
    /* .textlayout-main-bx .tlm-heading{
        font-size: 28px;
    } */

    /* .textlayout-main-bx .tlm-heading.heading-24 br{
        display: none;
    } */
}

@media screen and (max-width:576px) {
    /* .textlayout-main-bx .tlm-heading{
        font-size: 24px;
    } */

    /* .textlayout-main-bx .tlm-heading.heading-24{
        font-size: 20px;
    } */

    /* .textlayout-main-bx .tlm-sub-text{
        font-size: 14px;
    } */
}

@media screen and (max-width:450px) {
    /* .textlayout-main-bx .tlm-heading{
        font-size: 20px;
    } */

    /* .textlayout-main-bx .tlm-sub-text br,
    .textlayout-main-bx .tlm-heading br{
        display: none;
    } */

}