.pci-gap{
    row-gap: 30px;
}

.partnership-card-item{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    position: relative;
    height: 100%;
    overflow: hidden;
    gap: 15px;
    padding: 15px;
    border-radius: 20px;
    background-color: #f5f5f7;
}

.partnership-card-item .pci-img{
    width: 40px;
    height: 40px;
    filter: grayscale(1);
    transition: all 0.3s ease-in-out;
}

.partnership-card-item .pci-data-bx .pci-heading{
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: 500;
    line-height: normal;
    color: #1d1d1f;
}

.partnership-card-item .pci-data-bx .pci-text{
    font-size: 14px;
    color: #1d1d1f;
    overflow: hidden;
}

.partnership-card-img-bx img {
    width: 100%;
    max-width: 335px;
    max-height: 446px;
    object-position: center;
    object-fit: contain;
    margin: auto;
    display: block;
}


@media screen and (max-width:768px) {

    .partnership-card-img-bx img {
        max-width: 320px;
        max-height: 320px;
    }
}
