/* --- readmore card item Start --- */
.rci-gap{
    row-gap: 30px;
}

.readmore-card-item{
    cursor: pointer;
    position: relative;
    padding: 25px;
    border-radius: 20px;
    background: #f5f5f7;
    overflow: hidden;
}

.readmore-card-item::after{
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, #00000000, #f5f5f7);
}

.readmore-card-item .rci-icon-bx{
    position: absolute;
    width: 40px;
    height: 40px;
    right: 20px;
    bottom: 20px;
    z-index: 2;
    padding: 5px;
    border-radius: 50%;
    color: #1d1d1f;
    background-color: white;
    box-shadow: 2px 4px 12px #00000014;
    transition: all 0.3s ease-in-out;
}

.readmore-card-item .rci-icon-bx:hover{
    transform: scale(0.9);
}

.readmore-card-item .rci-data-bx .rci-heading{
    font-size: 18px;
    margin-bottom: 20px;
    font-weight: 600;
    line-height: normal;
    color: #1d1d1f;
}

.readmore-card-item .rci-data-bx .rci-text{
    font-size: 14px;
    color: #1d1d1f;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    padding-right: 70px;
}

.readmore-card-img-bx {
    height: 100%;
}

.readmore-card-img-bx img {
    width: 100%;
    max-width: 335px;
    max-height: 446px;
    object-position: center;
    object-fit: contain;
    margin: auto;
    display: block;
    position: sticky;
    top: 95px;
}

@media screen and (max-width:768px) {
    .readmore-card-img-bx{
        margin-bottom: -100px;
        position: relative;
        z-index: -1;
    }

    .readmore-card-img-bx img {
        max-width: 320px;
        max-height: 320px;
    }
}

@media screen and (max-width:576px) {
    .rci-gap{
        row-gap: 20px;
    }

    .readmore-card-item .rci-data-bx .rci-heading{
        font-size: 16px;
    }
}
/* --- readmore card item End --- */