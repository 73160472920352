/* --- platform section card start --- */
.psc-gap{
    row-gap: 30px;
}

.platform-section-card{
    cursor: pointer;
    position: relative;
    min-height: 200px;
    padding: 25px;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    position: relative;
    width: 100%;
    border-radius: 20px;
    background: var(--background-color3);
    overflow: hidden;
}

.platform-section-card > svg{
    width: 28px;
    height: 28px;
    color: #1f1f1d;
}

.platform-section-card .psc-data-bx .psc-heading{
    font-size: 16px;
    margin-bottom: 8px;
    font-weight: 600;
    line-height: 1.4;
    color: #1f1f1d;
}

.platform-section-card .psc-data-bx .psc-text{
    font-size: 14px;
    color: #1f1f1d;
    /* max-height: 0px; */
    overflow: hidden;
    transition: all 0.3s ease-in-out;
}

/* @media screen and (min-width:576px) {   
    .platform-section-card:hover .psc-data-bx .psc-text{
        max-height: 65px;
    }
} */

@media screen and (max-width:576px) {
    .psc-gap{
        row-gap: 20px;
    }
    
    .platform-section-card{
        min-height: inherit;
        padding: 8px;
        background: none;
        justify-content: inherit;
    }

    .platform-section-card .psc-data-bx{
        margin-top: 15px;
    }

    .platform-section-card .psc-data-bx .psc-text{
        max-height: initial;
    }
}

/* --- platform section card end --- */