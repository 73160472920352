/* --- platform section card start --- */
.asic-gap{
    row-gap: 30px;
}

.account-steps-infp-card{
    cursor: pointer;
    position: relative;
    min-height: 200px;
    padding: 25px;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    position: relative;
    width: 100%;
    border-radius: 20px;
    background-color: var(--background-white);
    overflow: hidden;
    box-shadow: 0 0 20px -5px #00000008;
}

.account-steps-infp-card .asic-num{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    padding: 5px;
    font-weight: 600;
    transition: all 0.3s ease-in-out;
}

.account-steps-infp-card .asic-num img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}

.account-steps-infp-card .asic-data-bx .asic-heading{
    font-size: 16px;
    margin-bottom: 8px;
    font-weight: 600;
    line-height: 1.4;
    color: var(--color1);
}

.account-steps-infp-card .asic-data-bx .asic-text{
    font-size: 14px;
    color: var(--color2);
    font-weight: 500;
    transition: all 0.3s ease-in-out;
}

@media screen and (max-width:576px) {
    .asic-gap{
        row-gap: 20px;
    }
    
    .account-steps-infp-card{
        min-height: inherit;
        padding: 16px;
        /* background: none; */
        justify-content: inherit;
    }

    .account-steps-infp-card .asic-data-bx{
        margin-top: 15px;
    }
}

/* --- platform section card end --- */