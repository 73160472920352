.custom-header{
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    z-index: 101;
    padding: 0 15px;
    height: 80px;
    background-color: var(--background-color2);
    transition: all 0.3s ease-in-out;
}

.custom-header.active{
    background-color: var(--background-color2-600);
    backdrop-filter: blur(5px);
}

.ch-inner{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    width: 100%;
    padding: 20px 0;
}

.ch-inner .ch-right,
.ch-inner .ch-left{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 160px;
    /* width: 194px; */
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
       -moz-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
}

.ch-inner .ch-left{
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
       -moz-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
}

.ch-inner .ch-right{
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
       -moz-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
}

@media screen and (min-width:992px) {
    .ch-inner .ch-left
    .ch-inner .ch-right{
        width: 160px;
        flex: 0 0 160px;
    }
}


/* --- Logo Css Start --- */
.ch-logo{
    position: relative;
    cursor: pointer;
}

.ch-logo img.logo-img{
    width: 210px;
    display: block;
    position: relative;
    top: 0px;
}
/* --- Logo Css End --- */

/* --- Menu Links Start --- */
.ch-menu-bx{
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
            gap: 5px;
    padding: 0 15px;
    width: 100%;
    border-radius: 15px;
    width: fit-content;
}

.ch-menu-bx .cmb-item{
    position: relative;
    z-index: 2;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    color: var(--color2);
    font-size: 14px;
    white-space: nowrap;
    padding: 8px 10px 8px;
    -webkit-border-radius: 50px;
       -moz-border-radius: 50px;
            border-radius: 50px;
    font-weight: 500;
    overflow: hidden;
    -webkit-transition:all 0.3s ease-in-out;
    -o-transition:all 0.3s ease-in-out;
    -moz-transition:all 0.3s ease-in-out;
    transition:all 0.3s ease-in-out;
}

.ch-menu-bx .cmb-item svg{
    width: 16px;
    height: 16px;
    margin-left: 5px;
}

.ch-menu-bx .cmb-item:hover{
    color: var(--color1);
}


.cmb-drop-item.active .cmb-item,
.ch-menu-bx .cmb-item.active{
    color: var(--color1);
    cursor: pointer;
}
/* --- Menu Links End --- */

/* --- --- */
.cmb-drop-item{
    position: relative;
    -webkit-border-radius: 50px;
       -moz-border-radius: 50px;
            border-radius: 50px;
}

.cmb-drop-item .cmb-drop-bx{
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
       -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
         -o-transform: translateX(-50%);
            transform: translateX(-50%);
    z-index: 0;
    padding-top: 60px;
    visibility: hidden;
}

.cmb-drop-item.active .cmb-drop-bx{
    visibility: visible;
}

.cmb-drop-item .cmb-drop-bx .cmb-drop-inner{
    padding: 10px;
    -webkit-border-radius: 12px;
       -moz-border-radius: 12px;
            border-radius: 12px;
    background-clip: padding-box;
    background-color: var(--background-color2);
    border: 1px solid var(--border-color2);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
       -moz-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateY(5px);
       -moz-transform: translateY(5px);
        -ms-transform: translateY(5px);
         -o-transform: translateY(5px);
            transform: translateY(5px);
    -webkit-transition:all 0.3s ease-in-out;
    -o-transition:all 0.3s ease-in-out;
    -moz-transition:all 0.3s ease-in-out;
    transition:all 0.3s ease-in-out;
}

.cmb-drop-item.active .cmb-drop-bx .cmb-drop-inner{
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(0px);
       -moz-transform: translateY(0px);
        -ms-transform: translateY(0px);
         -o-transform: translateY(0px);
            transform: translateY(0px);
}

.cdi-main{
    width: 300px;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.cdi-main .cdi-main-item{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    width: calc(50% - 4px);
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 6px 6px 6px;
    -webkit-border-radius: 5px;
       -moz-border-radius: 5px;
            border-radius: 5px;
    font-weight: 500;
    color: var(--color2);
    font-size: 14px;
    margin-top: 0px;
    -webkit-transition: 0.3s ease;
    -o-transition: 0.3s ease;
    -moz-transition: 0.3s ease;
    transition: 0.3s ease;
}

.cdi-main .cdi-main-item img{
    width: 20px;
    height: 20px;
    opacity: 0.4;
    -webkit-transition: 0.3s ease;
    -o-transition: 0.3s ease;
    -moz-transition: 0.3s ease;
    transition: 0.3s ease;
}

.cdi-main .cdi-main-item:first-child{
    margin-top: 0;
}

.cdi-main .cdi-main-item:hover img{
    opacity: 1;
}

.cdi-main .cdi-main-item:hover{
    color: var(--color1);
    cursor: pointer;
}

.cdi-main .cdi-main-item.active{
    color: var(--color1);
    cursor: pointer;
}
/* --- --- */


/* --- --- */
.signinup-btn-bx{
    display: flex;
    column-gap: 8px;
}
/* --- --- */

/* --- Aside Menu Icon Start --- */
.menu-icon-bx {
    width: 30px;
    height: 30px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 30px;
       -moz-box-flex: 0;
        -ms-flex: 0 0 30px;
            flex: 0 0 30px;
    display: none;
    -webkit-justify-content: space-around;
        -ms-flex-pack: distribute;
            justify-content: space-around;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 0px;
    cursor: pointer;
    margin-left: 15px;
}

.menu-icon-bx span {
    display: block;
    width: 100%;
    height: 3px;
    -webkit-border-radius: 100px;
       -moz-border-radius: 100px;
            border-radius: 100px;
    opacity: 0.8;
    background-color: var(--background-color1);
    -webkit-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}

/* .menu-icon-bx span:nth-child(3) {
    width: 60%;
    margin-right: auto;
} */

.menu-icon-bx:hover span,
.menu-icon-bx.active span{
    opacity: 1;
}

/* .menu-icon-bx.active span:nth-child(1) {
    width: 50%;
    margin-left: auto;
}

.menu-icon-bx.active span:nth-child(2) {
    width: 90%;
}

.menu-icon-bx.active span:nth-child(3) {
    width: 50%;
    margin-right: auto;
} */
/* --- Aside Menu Icon End --- */


/* --- @media Css Start --- */
@media screen and (max-width:1250px) {
    .ch-menu-bx{
        /* padding-right: 120px; */
        padding-right: 0;
    }
}

@media screen and (max-width:1024px) {

    .custom-header{
        padding: 0 15px;
    }
    
}

@media screen and (max-width:992px) {
    /* --- @Menu Links Start --- */
    .ch-menu-bx{
        display: none;
    }
    /* --- @Menu Links End --- */

    .ch-inner .ch-right,
    .ch-inner .ch-left{
        width: auto;
    }

    .menu-icon-bx{
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
    }
}

@media screen and (max-width:576px) {

    .menu-icon-bx{
        margin-left: 10px;
    }

    .custom-header{
        height: auto;
    }
    
}

@media screen and (max-width:460px) {
    .ch-logo img.logo-img{
        width: 160px;
    }

    .signinup-btn-bx .common-btn-item:nth-child(1){
        display: none;
    }
}

@media screen and (max-width:392px) {
    .ch-inner{
        padding: 10px 0;
    }
}
/* --- @media Css End --- */