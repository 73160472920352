/* --- Seo Number Card Start --- */
.snc-gap{
    row-gap: 30px;
}

.seo-number-card{
    padding: 16px;
    display: flex;
    height: 100%;   
    position: relative;
    width: 100%;
    gap: 15px;
    border-radius: 15px;
    background-color: white;
    box-shadow: 0 0 20px -5px #00000008;
}

.seo-number-card > svg{
    width: 32px;
    height: 32px;
    color: #1d1d1f;
    position: relative;
    top: 5px;
}

.seo-number-card .snc-data-bx .snc-number{
    font-size: 32px;
    margin-bottom: 4px;
    font-weight: 600;
    line-height: normal;
    color: #1d1d1f;
}

.seo-number-card .snc-data-bx .snc-text{
    font-size: 16px;
    font-weight: 400;
    color: #1d1d1f;
}

@media screen and (max-width:992px) {
    .seo-number-card .snc-data-bx .snc-number{
        font-size: 32px;
    }
}

@media screen and (max-width:768px) {
    .seo-number-card .snc-data-bx .snc-number{
        font-size: 28px;
    }
}

@media screen and (max-width:576px) {
    .snc-gap{
        row-gap: 20px;
    }
    
    .seo-number-card{
        padding: 16px;
        flex-direction: column;
    }

    .seo-number-card{
        padding: 8px;
    }

    .seo-number-card > svg{
        width: 28px;
        height: 28px;
    }

    .seo-number-card .snc-data-bx{
        font-size: 24px;
        margin-top: 5px;
    }

    .seo-number-card .snc-data-bx .snc-number{
        font-size: 24px;
    }

    .seo-number-card .snc-data-bx .snc-text{
        font-size: 12px;
    }
}

/* --- Seo Number Card End --- */