/* --- Common colors end --- */
:root{

    /* --- Text Color Code --- */
    --white: #ffffff;
    --color1: #1D1D1F;
    --color2: #6e6e73;

    /* --- Background Color Code --- */
    --background-white: #ffffff;

    --background-color1: #1D1D1F;

    --background-color2: #fafafc;
    --background-color2-800: rgba(249, 249, 251, 0.8);
    --background-color2-600: rgba(249, 249, 251, 0.6);

    --background-color3: #f5f5f7;

    /* --- Border Color Code --- */
    --border-white: #ffffff;
    --border-color1: #1D1D1F;
    --border-color2: #d2d2d7;

}
/* --- Common colors end --- */
